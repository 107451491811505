@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=DM+Sans:opsz,wght@9..40,100;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&family=Montserrat:wght@100;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// SCSS RESET

*, *:before, *:after{
    box-sizing: border-box;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video{
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: none;
    background-color: black;
    color: white;
}

footer, header, nav, section, main{
    display: block;
}

body{
    line-height: 1;
}

ol, ul{
    list-style: none;
}

blockquote, q{
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after{
    content: '';
    content: none;
}

table{
    border-collapse: collapse;
    border-spacing: 0;
}

input{
    -webkit-appearance: none;
    border-radius: 0;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: #8e8e8e;
    border-radius: 20px;
}

::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 20px;
}

// FONTS
$font_bebas: 'Bebas Neue', sans-serif;
$font_dm_sans: 'DM Sans', sans-serif;
$font_montserrat: 'Montserrat', sans-serif;
$font_roboto: 'Roboto', sans-serif;

// COLORS
$gold: rgb(212 175 55);
$light-gray: rgb(197, 197, 197);
$dark-gray: rgb(131, 131, 131);

// SCREEN SIZES
$mobile: 1010px;
$xsmall: 785px;

// LAYOUT
$component_padding: 2rem 8rem 2rem 8rem;

// BUTTON COMPONENT
.button {
    background-color: $gold;
    color: black;
    padding: 1rem 1.5rem 1rem 1.5rem;
    border-radius: .5rem;
    cursor: pointer;
    width: fit-content;
    font-family: $font_dm_sans;
    font-weight: 500;
    text-decoration: none;

}

// GOLD TEXT
#gold {
    color: $gold;
}

// PROGRAM COMPONENT
.program {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid white;
    border-radius: 1rem;
    width: 23rem;
    height: 23rem;
    padding: 2rem;
    
    .icon {
        size: 2px;
    }

    .icon-container {
        border: 2px solid white;
        padding: 1rem;
        border-radius: 50%;
        margin-bottom: 1rem;
    }
    
    .program-title {
        font-weight: 600;
        font-size: 1.5rem;
        border: none;
        font-family: $font_dm_sans;
        margin-bottom: 1rem;
        
    }
    
    span {
        max-width: 20rem;
        font-weight: 300;
        font-family: $font_dm_sans;
    }
}
.App {

    .home-container {
        position: relative;
        // HEADER
        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            font-family: $font_dm_sans;
            font-weight: 600;
            padding: $component_padding;
            position: fixed;
            height: fit-content;
            z-index: 100;

            .header-left {
                display: flex;
                align-items: center;
                font-size: 1.15rem;
                gap: 3rem;
                height: auto;
                width: auto;

                .header-logo {
                    img {
                        width: 6rem;
                    }
                }

                .header-links {
                    @media (max-width: $mobile) {
                        display: none;
                    }

                    display: flex;
                    gap: 1.5rem;
                    cursor: pointer;
                    
                    .section {
                        transition: .25s color ease-in;
                        &:hover {
                            color: $gold;
                        }

                        &.section.scrolled {
                            color: $gold;
                        }
                    }

                }
            }

            .reservation {
                background-color: $gold;
                color: black;
                padding: 1rem 1.5rem 1rem 1.5rem;
                border-radius: .5rem;
                cursor: pointer;

                a {
                    background-color: $gold;
                    text-decoration: none;
                    color: black;
                    font-weight: 900;
                }
            }

            .header-hamburger {
                font-size: 2.5rem;
                color: $gold;
                cursor: pointer;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                transition: left 0.3s ease-in-out;
                display: flex;
                flex-direction: column;
                gap: 10rem;
            }

            .mobile-menu {
                position: fixed;
                top: 0;
                right: -100%;
                width: 250px;
                transition: right 0.3s ease-in-out;
                display: flex;
                flex-direction: column;
                height: 100%;

                .mobile-close {
                    font-size: 1.5rem;
                    font-weight: 500;
                    margin-top: .25rem;
                    cursor: pointer;
                    width: 100%;
                    padding-left: 14rem;
                    color: white;
                    margin-bottom: 4rem;
                }

                div {
                    display: flex;
                    flex-direction: column;
                    gap: 4rem;
                    align-items: center;

                    .mobile-span {
                        font-size: 2rem;
                        cursor: pointer;
                        transition: .5, color, ease-in;

                        &:hover {
                            color: $gold;
                        }

                        &.mobile-span.scrolled {
                            color: $gold;
                        }
                    }
                }
            }

            .mobile-menu.active {
                right: 0; 
            }

            &.header-scrolled {
                background-color: white;

                .header-left {
                    color: black;
                    background-color: white;

                    .header-logo {
                        img {
                            background-color: white;
                            display: block;
                        }
                    }

                    .header-links {
                        background-color: white;
                        span {
                            color: black;
                            background-color: white;

                            &:hover {
                                color: gold;
                            }
                        }
                    }
                }

                .header-hamburger {
                    background-color: white;
                    color: black;
                    box-shadow: none;
                }
            }
        }

        // ABOUT

        .about {
            display: flex;
            align-items: center;
            width: 100%;
            padding: $component_padding;
            padding-bottom: 8rem;
            padding-top: 10rem;

            .about-tagline-container {
                display: flex;
                flex-direction: column;
                width: 100%;

                .about-tagline {
                    display: flex;
                    flex-direction: column;
                    font-family: $font_bebas;
                    font-size: 3.5rem;
                    font-weight: 700;
                    margin-bottom: 5rem;

                    span:last-child {
                        @media (max-width: $xsmall) {
                            padding-left: 1rem;
                        }

                        padding-left: 22.5rem;
                    }
                }

                .about-desc {
                    display: flex;
                    width: 100%;
                    font-size: 1.25rem;
                    font-weight: 300;
                    margin-bottom: 5rem;
                    font-family: $font_dm_sans;

                    span {
                        width: 50rem;
                    }
                }

                .about-links {
                    display: flex;
                    align-items: center;
                    gap: 2rem;
                    font-family: $font_dm_sans;

                    div {
                        font-weight: 500;
                    }

                    span {
                        cursor: pointer;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .about-image {
                @media (max-width: $mobile) {
                    display: none;
                }

                filter: grayscale(20%);
                box-shadow: 0 0 8px 8px black inset;
            }
        }

        // PROGRAMS

        .programs {
            @media (max-width: $mobile) {
                align-items: center;
            }
            
            display: flex;
            flex-direction: column;
            padding: $component_padding;
            padding-bottom: 10rem;

            #programs-title {
                
                font-family: $font_montserrat;
                font-size: 3rem;
                font-weight: 700;
                margin-bottom: 5rem;
            }

            .program-container {
                @media (max-width: 1694px) and (min-width: $mobile) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @media (max-width: $mobile) {
                    grid-template-columns: repeat(1, 1fr);
                }

                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 1rem;
            }

        }

        // CREDENTIALS

        .credentials {
            @media (max-width: $mobile) {
                img {
                    display: none;
                }
            }

            img {
                height: fit-content;
                border-radius: 50%;                
            }

            display: flex;
            padding: $component_padding;
            gap: 5rem;
            padding-bottom: 8rem;
            align-items: center;

            .credential-details {
                display: flex;
                flex-direction: column;

                .credential-headline {
                    font-family: $font_montserrat;
                    font-size: 2rem;
                    font-weight: 900;
                    margin-bottom: 3rem;
                }

                .credential-text {
                    display: flex;
                    flex-direction: column;
                    gap: .75rem;

                    #credential-title {
                        font-family: $font_dm_sans;
                        font-size: 1.5rem;
                        font-weight: 700;
                        margin-bottom: 2rem;
                    }

                    span {
                        font-size: 1rem;
                        font-weight: 600;
                        font-family: $font_dm_sans;
                    }

                    #credential-desc {
                        margin-bottom: 2rem;
                        margin-top: 2rem;
                        font-weight: 400;
                    }
                }

                .credential-button {
                    font-weight: 800;
                }
            }
        }

        // TESTIMONIALS
        .testimonials {
            @media (max-width: $mobile) {
                align-items: center;
            }

            padding: $component_padding;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 1rem;
            margin-bottom: 8rem;

            span {
                font-weight: 700;
                font-family: $font_montserrat;
                font-size: 2rem;
                margin-bottom: 2rem;
            }

            .testimonial-container {
                @media (max-width: 1694px) and (min-width: 1310px) {
                    grid-template-columns: repeat(3, 1fr);
                }

                @media (max-width: 1310px) and (min-width: $mobile) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @media (max-width: $mobile) {
                    grid-template-columns: repeat(1, 1fr);
                }

                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 1rem;

                .testimonial {
                    @media (max-width: 1694px) {
                    }

                    border: 2px solid white;
                    border-radius: 1rem;
                    padding: 2rem;
                    font-family: $font_dm_sans;
                    height: 25rem;
                    width: 25rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
            
                    .testimonial-text {
                        max-width: 25rem;
                        margin-bottom: 1rem;
                        font-weight: 500;
                    }
            
                    .testimonial-user {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
            
                        .user-info {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
            
                            .user-name {
                                font-weight: 600;
                                font-size: 1.25rem;
                                margin-bottom: .5rem;
                            }
            
                            .testimonial-date {
                                font-size: .8rem;
                            }
                        }
            
                        img {
                            width: 5rem;
                            border-radius: 50%;
                            border: 2px solid white;
                        }
                    }
                }
            }
        }

        // CONTACT

        .contact-container {
            display: flex;
            align-items: center;
            gap: 2rem;
            margin-bottom: 5rem;
            .contact {
                display: flex;
                flex-direction: column;
                padding: $component_padding;
            
                .contact-header {
                    display: flex;
                    flex-direction: column;
            
                    .contact-tag {
                        font-family: $font_montserrat;
                        font-weight: 700;
                        font-size: 2rem;
                        margin-bottom: 2rem;
            
                        .tag-left {
                            color: $gold
                        }
                    }
            
                    .contact-text {
                        font-family: $font_dm_sans;
                        font-size: 1.15rem;
                        margin-bottom: .5rem;
                    }
                }
            
                form {
                    display: flex;
                    flex-direction: column;
                    max-width: 45rem;
                    height: 100%;
                    gap: 1rem;
                    margin-top: 3rem;
                    
                    input{
                        border: none;
                        height: 3rem;
                        border-radius: .5rem;
            
                        &::placeholder {
                            font-family: $font_montserrat;
                            font-size: 1rem;
                            font-weight: 600;
                            color: gray;
                        }
                    }
            
                    select {
                        font-size: 1rem;
                        height: 3rem;
                        border-radius: .5rem;
                        font-family: $font_montserrat;
                        font-weight: 600;
                        color: gray;
            
                        option {
                            color: black;
                        }
                    }
                    
                    div {
                        display: flex;
                        align-items: center;
                        gap: 2rem;
                        button {
                            height: 3rem;
                            width: 40%;
                            border-radius: .5rem;
                            cursor: pointer;
                            background-color: $gold;
                            font-family: $font_dm_sans;
                            font-weight: 500;
                            font-size: 1rem;
                            justify-content: center;
                            align-items: center;
                            margin-top: 1rem;
                        }
            
                        .notification-text {
                            color: white;
                            font-size: 1rem;
                            font-weight: 700;
                            font-family: $font_montserrat;
                            margin-top: 1rem;
                        }
                    }
                    
                }
            }

            img {
                height: 35rem;
                filter: grayscale(40%);
                border-radius: .5rem;
            }
        }

        // FOOTER 

        .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: $font_dm_sans;
            padding: $component_padding;

            .footer-mid, .footer-right{
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }

            .footer-title {
                font-weight: 600;
            }
        }


        // NEWSLETTER BUTTON
        .newsletter-button {
            position: fixed;
            background-color: $dark-gray;
            border-radius: .5rem;
            color: black;
            padding: 1rem;
            right: .5rem;
            bottom: 2rem;
            cursor: pointer;
            transition: .25s background-color ease-in;

            &:hover {
                background-color: white;
            }
        }

        // NEWSLETTER BANNER
        .newsletter-banner{
            background-color: white;
            color: black;
            position: fixed;
            display: flex;
            right: -50rem;
            bottom: .5rem;
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 50rem;
            height: 5rem;
            padding: 1rem;
            border-radius: .5rem;
            transition: right 0.3s ease-in-out;

            &.show {
                right: .5rem;
            }

            .banner-body {
                background-color: white;
                width: 100%;
                position: relative;
                padding: 1rem;
                padding-top: 1.5rem;

                .banner-close {
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-color: white;
                    align-self: flex-end;
                    color: black;
                    cursor: pointer;
                    margin-bottom: .5rem;
                }
                .banner-main {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    justify-content: space-between;
                    background-color: white;
                    align-items: center;

                    .banner-text {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        background-color: white;
        
                        span {
                            background-color: white;
                            font-family: $font_bebas;
                            color: black;
                        }
                    }
                        
                    .banner-form {
                        background-color: white;
                        color: black;
                        display: flex;
                        align-items: center;
                        gap: .5rem;
                        width: fit-content;

                        input {
                            border: 1px solid rgba(0, 0, 0, 0.5);
                            height: 2rem;
                            border-radius: .5rem;
                            width: 15rem;
                            
                            &::placeholder {
                                padding: .5rem;
                                font-family: $font_dm_sans;
                                font-weight: 500;
                            }
                        }
        
                        .banner-button {
                            background-color: $gold;
                            padding: .5rem;
                            border-radius: .5rem;
                            font-family: $font_dm_sans;
                            font-weight: 600;
                            color: black;
                            cursor: pointer;
                        }
                    }

                }
            }
        }
    }

    // NEWSLETTER LANDING PAGE
    .newsletter-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        height: 100vh;
        
        .newsletter-main {
            @media (max-width: $mobile) {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 35rem;
            }
            display: flex;
            height: 25rem;
            gap: 4rem;
            
            .newsletter-headshot {

                img {
                    width: 10rem;
                }
            }
            
            .newsletter-details {
                @media (max-width: $mobile) {
                    align-items: center;
                }
                display: flex;
                flex-direction: column;
                gap: 2rem;

                #newsletter-title {
                    font-size: 3rem;
                    font-family: $font_montserrat;
                    font-weight: 600;
                    width: auto;
                }
                
                #newsletter-text {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    max-width: 31rem;
                    font-family: $font_dm_sans;
                    font-size: 1.25rem;
                    color: $light-gray;
                }

                .newsletter-form {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: .5rem;
                    
                    form {
                        display: flex;
                        gap: 1rem;

                        input {
                            border: none;
                            height: 3rem;
                            border-radius: .5rem;
                            width: 22rem;
                            
                
                            &::placeholder {
                                font-family: $font_montserrat;
                                font-size: 1.15rem;
                                font-weight: 600;
                                color: gray;
                                padding: 1rem;
                                text-indent: 1rem;
                            }
                        }

                        button {
                            background: none;
                            border: none;
                            font-size: 1rem;
                            font-weight: 600;
                            border-radius: .5rem;
                            background-color: rgb(88,54,193);
                            color: white;
                            padding-left: 1.75rem;
                            padding-right: 1.75rem;
                            cursor: pointer;
                        }
                    }

                    div {
                        font-size: .85rem;
                        color: $light-gray;
                        font-family: $font_roboto;

                        #unsub {
                            font-family: $font_roboto;
                            color: $light-gray;
                            cursor: pointer;

                            &:hover {
                                cursor: pointer;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

        .social-links {
            @media (max-width: $mobile) {
                flex-direction: column;
                gap: 2rem;
            }
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 5rem;
            width: 45rem;   
                
            .socials-link {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 18rem;
                gap: .5rem;
                font-family: $font_dm_sans;
                font-weight: 600;
                background-color: gray;
                padding: 1.5rem;
                border-radius: .5rem;
                cursor: pointer;
                
                .social-icon {
                    background-color: gray;
                }

                span {
                    background-color: gray;
                    color: white;
                }
            }
        }
    }
}